

.list-container__title {
    color: var(--main-text-color);
    margin-left: 20px;
}




