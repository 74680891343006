
.login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-bg-color);

    .login {
        width: 500px;
        padding: 20px;
        background-color: white;
        border-radius: 20px;

        h2 {
            padding-left: 10px;
        }

        .red {
            color: red;
        }

        .registrarme-btn {
            text-align: center;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            
            .btn-primary {
                color:black;
                border-color: black;
                background-color: var(--main-bg-color);
                margin: 5px;
                align-self: center;
                font-weight: bold;

                &:hover {
                    background-color: green;
                }
            }

            .btn-registrado {
                background-color: white;

                &:hover {
                    background-color: var(green);
                }
            }

            .btn-ingresar-google {
                display: flex;
                flex-direction: row;
                background-color: white;

                .logo-google {
                    width: 35px;
                    height: 25px;
                    margin-right: 5px;
                } 
            }
        }
    }
}
