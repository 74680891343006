
.cart-widget {
    display: flex;
    align-items: center;
    // margin-right: 5px;
    gap: 4px;
    visibility: hidden; // la clase visibility es compatible para con transiciones de mostrar y ocultar elementos, porque
                        // a diferencia del display none, no me mueve el resto de los componentes de lugar, ya que el elemento 
                        // sigue estando, pero oculto
    color: black;
    
    &:hover {
        color: white;
    }

    .cart-icon {
        font-size: 20px;
    }

    span {
        font-size: 20px;
    }

    &-active {
        visibility: visible;
    }
}