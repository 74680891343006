@import '../../styles/misc/mixins.scss';

.footer-container {
    background-color: var(--main-bg-color);
    margin: 0 auto;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    height: 100px;
    width: 100%;

    .sectionFooter {
        display: flex;
        align-items: center;

        .footer__logo {
            @include sizewh(3rem, 3rem);
            margin: 1rem;
            align-items: center;
            justify-content: center;
        }

        .tw {
            @include sizewh(2.6rem, 2.6rem);
        }

        .footer__links {
            margin: 1rem;

            &:hover {
                color: white;
            }
        }
    }
}

.footer-fijado {
    position: fixed;
    bottom: 0;
}

@media screen and (max-width: 430px) {
    .footer-container {
        height: 50px;

        .sectionFooter {
            .footer__logo {
                @include sizewh(1.5rem, 1.5rem);
                margin: 0.5rem;
            }
        }
    }
}