

.header {
    background-color: var(--main-bg-color);
    position: sticky;
    top: 0;

    .header__container {
        max-width: 900px;
        margin: 0 auto;
        padding: 30px 0;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header__logo {
            width: 120px;
        }
        
        .navbar {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;

            .navbar__link {
                font-size: 20px;
                text-transform: uppercase;
                cursor: pointer;
                font-weight: 700;
                color: var(--main-text-color);
                text-decoration: none;
                padding-bottom: 5px;
                &:hover {
                            color: white;
                            border-bottom: 2px solid white;
                        }
            }
        }
    }

    .collapse-navbar {
        display: none;
    }

    .user {
        max-width: 900px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: var(--main-text-color);
        padding: 20px;
        padding-bottom: 5px;
        gap: 10px;

        .btn-user {
            background: none;
            border: none;
            color: black;
            font-size: 20px;
            padding-bottom: 12px;

            &:hover {
                color: white;
            }
        }
    }

    .user-options {
        display: flex;
        flex-direction: column;
        max-width: 900px;
        margin: 0 auto;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 30px;
        padding-bottom: 10px;

        p { 
            &:hover {
                color: white;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .header {
        .header__container {
            
            .navbar {
                display: none;
            }
        }

        

        .collapse-navbar {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin: 0 0.5rem;
          
            animation: bounce; /* referring directly to the animation's @keyframe declaration */
            animation-duration: 2s; /* don't forget to set a duration! */
    
            .navbar {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 5px;
                margin-right: 20px;
                padding-top: 0;
    
                .navbar__link {
                    font-size: 15px;
                    text-transform: uppercase;
                    cursor: pointer;
                    font-weight: 700;
                    color: var(--main-text-color);
                    text-decoration: none;
                    padding-bottom: 5px;
                    &:hover {
                                color: white;
                                border-bottom: 2px solid white;
                            }
                }
            }
        }
    }
}