
.loader {
    background-color: rgba(255,255,255,0.7);
    width: 100%;
    height: 100vh;
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    z-index: 1000;
    justify-content: center;
}